import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QMCloudsLaptopMobileImage from '../../components/images/QMCloudsLaptopMobileImg';
import QMCloudsMobiiliEtusivuImg from '../../components/images/QMCloudsMobiiliEtusivuImg';
import QMCloudsTabletDokumenttipohjatImg from '../../components/images/QMCLoudsTabletDokumenttipohjatImg';
import LinkTo from '../../components/LinkTo';
import QualityManualRequestWithBg from '../../components/QualityManualRequestWithBg';
import urls from '../../components/urls';

export default () => {
  const { t } = useTranslation();
  return (
    <>
      <Container className="container-space article-text">
        <Row>
          <Col>
            <h2 className="text-blue">
              {t('ImplementedQualitySystemArticleText2')}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={7} className="mb-4">
            <ul>
              <li>{t('ImplementedQualitySystemArticleTextLi1')}</li>
              <li className="mt-2">
                {t('ImplementedQualitySystemArticleTextLi2')}
              </li>
              <li className="mt-2">
                {t('ImplementedQualitySystemArticleTextLi3')}
              </li>
              <li className="mt-2">
                {t('ImplementedQualitySystemArticleTextLi4')}
              </li>
            </ul>
          </Col>
          <Col lg={5} className="m-auto">
            <QMCloudsTabletDokumenttipohjatImg />
          </Col>
        </Row>
      </Container>
      <QualityManualRequestWithBg />
      <Container className="container-space article-text">
        <h2 className="text-blue">
          {t('ImplementedQualitySystemArticleText3')}
        </h2>
        <ol>
          <li>{t('ImplementedQualitySystemArticleTextOl1')}</li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextOl2')}
          </li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextOl3')}
          </li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextOl4')}
          </li>
        </ol>
        <LinkTo url={urls.home} text={t('ImpelementedReadMore')} />
        <h2 className="text-blue mt-5">
          {t('ImplementedQualitySystemArticleText4')}
        </h2>
        <p>{t('ImplementedQualitySystemArticleText5')}</p>
        <p>{t('ImplementedQualitySystemArticleText6')}</p>
        <p>{t('ImplementedQualitySystemArticleText7')}</p>
        <p>{t('ImplementedQualitySystemArticleText8')}</p>
        <p>{t('ImplementedQualitySystemArticleText9')}</p>
      </Container>
      <QualityManualRequestWithBg />
      <Container className="container-space article-text">
        <Row>
          <Col>
            <h2 className="text-blue">
              {t('ImplementedQualitySystemArticleText10')}
            </h2>
            <p>{t('ImplementedQualitySystemArticleText11')}</p>
            <p>{t('ImplementedQualitySystemArticleText12')}</p>
            <p>{t('ImplementedQualitySystemArticleText13')}</p>
            <p>{t('ImplementedQualitySystemArticleText14')}</p>
            <LinkTo
              url={urls.qualitySystemUrl}
              text={t('ImplementedQualitySystemReadMoreQuality')}
            />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={6}>
            <h2 className="text-blue">
              {t('ImplementedQualitySystemArticleText15')}
            </h2>
            <p>{t('ImplementedQualitySystemArticleText16')}</p>
            <p>{t('ImplementedQualitySystemArticleText17')}</p>
            <p>{t('ImplementedQualitySystemArticleText18')}</p>
          </Col>
          <Col lg={6} className="m-auto">
            <QMCloudsLaptopMobileImage />
          </Col>
        </Row>

        <h2 className="text-blue mt-5">
          {t('ImplementedQualitySystemArticleText19')}
        </h2>
        <ul>
          <li>{t('ImplementedQualitySystemArticleTextLi6')}</li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextLi7')}
          </li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextLi8')}
          </li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextLi9')}
          </li>
          <li className="mt-2">
            {t('ImplementedQualitySystemArticleTextLi10')}
          </li>
        </ul>
        <p>{t('ImplementedQualitySystemArticleText20')}</p>
        <LinkTo
          url={urls.qualityUrl}
          text={t('ImplementedQualitySystemBenefits')}
        />
        <Row className="mt-5">
          <Col lg={8} className="mb-4">
            <h2 className="text-blue ">
              {t('ImplementedQualitySystemArticleText21')}
            </h2>
            <p>{t('ImplementedQualitySystemArticleText22')}</p>
            <p>{t('ImplementedQualitySystemArticleText23')}</p>
            <p>{t('ImplementedQualitySystemArticleText24')}</p>
            <p>{t('ImplementedQualitySystemArticleText25')}</p>
            <div className="mt-5">
              <Link to={urls.articlePageUrl} className="text-orange bold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 link-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                  />
                </svg>{' '}
                {t('BackToArticles')}
              </Link>
            </div>
          </Col>
          <Col lg={4} className="m-auto">
            <QMCloudsMobiiliEtusivuImg />
          </Col>
        </Row>
      </Container>
    </>
  );
};
