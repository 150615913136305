import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import ImplementedQualitySystemArticle from '../pages-partials/article-implemented-quality-system/ImplementedQualitySystemArticle';
import ImplementedQualitySystemArticleHero from '../pages-partials/article-implemented-quality-system/ImplementedQualitySystemArticleHero';

const ImplementedQualitySystemPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('ImplementedQualitySystemArticleSeoTitle')}
      description={t('ImplementedQualitySystemArticleSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/how-to-implement-the-quality-system-in-your-company"
      lang={locale}
    />

    <ImplementedQualitySystemArticleHero />
    <ImplementedQualitySystemArticle />
  </Layout>
);

export default withI18next({ ns: 'common' })(ImplementedQualitySystemPage);
